.marquee-container {
	 overflow-x: hidden !important;
	 display: flex !important;
	 flex-direction: row !important;
	 position: relative;
	 width: 100%;
}
 .marquee-container:hover div {
	 animation-play-state: var(--pause-on-hover);
}
 .marquee-container:active div {
	 animation-play-state: var(--pause-on-click);
}
 .overlay {
	 position: absolute;
	 width: 100%;
	 height: 100%;
}
 .overlay::before, .overlay::after {
	 background: linear-gradient(to right, var(--gradient-color));
	 content: "";
	 height: 100%;
	 position: absolute;
	 width: var(--gradient-width);
	 z-index: 2;
}
 .overlay::after {
	 right: 0;
	 top: 0;
	 transform: rotateZ(180deg);
}
 .overlay::before {
	 left: 0;
	 top: 0;
}
 .marquee {
	 flex: 0 0 auto;
	 min-width: 100%;
	 z-index: 1;
	 display: flex;
	 flex-direction: row;
	 align-items: center;
	 animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
	 animation-play-state: var(--play);
	 animation-delay: var(--delay);
	 animation-direction: var(--direction);
}
 @keyframes scroll {
	 0% {
		 transform: translateX(0%);
	}
	 100% {
		 transform: translateX(-100%);
	}
}
